<template>
    <div class="inspectionTaskManage-container">
        <div id="component">
            <CSBreadcrumb v-if="previousRoute.name == 'propertyData'"/>
            <div class="filter-panel">
                <CSSelect style="margin-right: 10px; width: 185px;">
                    <el-date-picker
                            v-model="queryParams.startDate"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择开始时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <span
                        style="
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 0 15px 0;
                        height: 2px;
                        width: 20px;
                        margin-right: 0;
                        background-color: #999;
                    "
                >
                </span>
                <CSSelect style="margin-left: 10px; width: 185px;">
                    <el-date-picker
                            v-model="queryParams.endDate"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择结束时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <template
                        v-if="[1001, 1002].includes(Number(staffInfo.dutyType))"
                >
                    <CSSelect>
                        <select v-model="queryParams.operatorId" style="padding-left: 5px;">
                            <option selected value="">
                                全部创建人
                            </option>
                            <option
                                    v-for="staff in staffList"
                                    :value="staff.id"
                                    :key="staff.id"
                            >
                                {{ staff.name }}
                            </option>
                        </select>
                    </CSSelect>
                </template>
                <CSSelect>
                    <select v-model="queryParams.performerId" style="padding-left: 5px;">
                        <option selected value="">全部巡检人</option>
                        <option
                                v-for="staff in staffList"
                                :value="staff.id"
                                :key="staff.id"
                        >
                            {{ staff.name }}
                        </option>
                    </select>
                </CSSelect>
                <CSSelect style="padding-left: 5px; margin-right: 30px;">
                    <select v-model="queryParams.isTimeout">
                        <option value="">异常状态不限</option>
                        <option :value="true">是</option>
                        <option :value="false">否</option>
                    </select>
                </CSSelect>
                <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="queryInspectTask()"
                >
                    查询
                </button>
                <div style="display: inline-block;float: right;">
                    <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            style="float: right;"
                            @click="openDownLoad()"

                    >
                        下载巡检记录
                    </button>
                    <div v-if="downState" class="grabbing" style="display: inline-block;float: right;">下载中</div>
                </div >

            </div>

            <div class="result-panel">

                <CSTable :thead-top="filterHeight">
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">巡检时间</th>
                            <th class="text-center">巡检类型</th>
                            <th class="text-center">巡检计划</th>
                            <th class="text-center">开始时间</th>
                            <th class="text-center">结束时间</th>
                            <th class="text-center">巡检人</th>
                            <th class="text-center">巡检是否异常</th>
                            <th class="text-center">巡检详情</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <template
                                v-for="(task, taskIndex) in inspectionTaskList"
                        >
                            <tr :key="taskIndex">
                                <td class="text-center">
                                    {{ task.scheduleDate }}
                                </td>
                                <td class="text-center">
                                    <!--                                    {{ judgmentAuthority()[task.type] || '-' }}-->
                                    {{ checkPlanToStr(task.type)}}

                                </td>
                                <td class="text-center">
                                    {{ task.name }}
                                </td>
                                <td class="text-center">
                                    {{ task.startTime }}
                                </td>
                                <td class="text-center">
                                    {{ task.endTime }}
                                </td>
                                <td class="text-center">
                                    {{ task.performer }}
                                </td>
                                <td class="text-center">
                                    {{ task.timeout ? "是" : "否" }}
                                </td>

                                <td class="text-center">
                                    <div
                                            class="btn btn-link"
                                            @click="lookInspectPointLog(task.id)"
                                    >
                                        查看
                                    </div>
                                </td>
                            </tr>
                            <template
                                    v-if="
                                    inspectPointLogShowStatus[task.id] &&
                                    inspectPointLogShowStatus[task.id]['status']
                                "
                            >
                                <tr :key="taskIndex + '_'"
                                >
                                    <td
                                            colspan="8"
                                            style="
                                            background: #e6e4e4;
                                            max-height: 300px;
                                            overflow-y: auto;
                                        "
                                    >
                                        <table
                                                class="footable table table-stripped toggle-arrow-tiny"
                                                style="border-color: #fff"
                                        >
                                            <thead>
                                            <tr>
                                                <th>巡检点</th>
                                                <th>位置</th>
                                                <th>签到</th>
                                                <th>签到时间</th>
                                                <th>签到是否异常</th>
                                                <th>检查项</th>
                                                <th>完成检查项是否异常</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-if="inspectPointLogShowStatus[task.id]['data'] &&
                                                    inspectPointLogShowStatus[task.id]['data'].length > 0">
                                                <tr
                                                        v-for="(log, logIndex) in inspectPointLogShowStatus[task.id]['data']"
                                                        :key="logIndex"

                                                >
                                                    <td style="border-bottom: 1px solid #fff">
                                                        {{ log.name }}
                                                    </td>
                                                    <td style="border-bottom: 1px solid #fff">
                                                        {{ log.addr }}
                                                    </td>
                                                    <td style="border-bottom: 1px solid #fff">
                                                        {{
                                                        log.checkinMethod !=
                                                        0
                                                        ? "需要"
                                                        : "不需要"
                                                        }}
                                                    </td>
                                                    <td style="border-bottom: 1px solid #fff">
                                                        <template
                                                                v-if="
                                                                log.checkinTime &&
                                                                log.checkinTime !=
                                                                    ''
                                                            "
                                                        >
                                                            {{
                                                            log.checkinTime.slice(
                                                            0,
                                                            log
                                                            .checkinTime
                                                            .length -
                                                            3
                                                            )
                                                            }}
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                        <template
                                                                v-if="
                                                                log.checkinTime &&
                                                                log.archives &&
                                                                log.archives !=
                                                                    '' &&
                                                                log.checkinMethod ==
                                                                    2
                                                            "
                                                        >
                                                            <div
                                                                    class="btn btn-link"
                                                                    @click="
                                                                    lookCheckInPhotos(
                                                                        log.archives
                                                                    )
                                                                "
                                                            >
                                                                查看图片
                                                            </div>
                                                        </template>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #fff">
                                                        {{
                                                        log.checkinTimeout
                                                        ? "是"
                                                        : "否"
                                                        }}
                                                    </td>
                                                    <td style="border-bottom: 1px solid #fff">
                                                        <template
                                                                v-if="
                                                                log.inspectItemCount >
                                                                0
                                                            "
                                                        >
                                                            <div
                                                                    class="btn btn-link"
                                                                    @click="
                                                                    lookInspectItemLog(
                                                                        log.id
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                log.inspectItemCount
                                                                }}
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </td>
                                                    <td style="border-bottom: 1px solid #fff">
                                                        {{
                                                        log.inspectTimeout
                                                        ? "是"
                                                        : "否"
                                                        }}
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </CSTable>

                <!-- 分页 -->
                <Pagination
                        name="pagination"
                        componentName="Pagination"
                ></Pagination>
            </div>

            <ViewModal name="viewModal" componentName="ViewModal"></ViewModal>
            <ViewImageModal/>


            <CSDialog
                    dialogTitle="下载巡检记录"
                    :dialogWidth="705"
                    :dialogVisible="openDownLoadFlag"
                    dialogConfirmBtnText="下载"
                    @onClose="openDownLoadFlag = false"
                    @onConfirm="downInspectTask"
            >
                <div
                        slot="dialog-content" class="dialog-content"
                        style="padding: 20px 30px 0; font-size: 24px;display: flex;align-items: center"
                >
                    <div style="display: inline-block;width: 144px;text-align: right">下载区间</div>
                    <CSSelect class="yueF" i-width="34px">
                        <select @change="changeTime" v-model="tiemrOut">
                            <option :value="2">按月</option>
                            <option :value="1">按周</option>
                        </select>
                    </CSSelect>
                </div>
                <div
                        slot="dialog-content" class="dialog-content"
                        style="padding: 30px 30px 20px; font-size: 24px;"
                        v-if="tiemrOut == 2"
                >
                    <span>选择下载月份</span>
                    <CSSelect class="yueF" i-width="40px">
                        <el-date-picker
                                v-model="dialogStartTimerMoun"
                                format="yyyy-MM"
                                value-format="yyyy-MM"
                                type="month"
                                placeholder="请选择">
                        </el-date-picker>
                        <!--                        <el-date-picker-->
                        <!--                                v-model="queryParams.startDate"-->
                        <!--                                type="date"-->
                        <!--                                format="yyyy-MM-dd"-->
                        <!--                                value-format="yyyy-MM-dd"-->
                        <!--                                placeholder="请选择开始时间"-->
                        <!--                                prefix-icon="el-icon-time"-->
                        <!--                                :editable="false"-->
                        <!--                        ></el-date-picker>-->
                    </CSSelect>
                </div>
                <div
                        slot="dialog-content" class="dialog-content"
                        style="padding: 30px 30px 20px; font-size: 24px;"
                        v-if="tiemrOut == 1"
                >
                    <span>选择下载日期</span>

                    <CSSelect class="yueF" i-width="40px">
                        <el-date-picker
                                v-model="dialogStartTimer"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择开始时间"
                                prefix-icon="el-icon-time"
                                :editable="false"
                        ></el-date-picker>
                    </CSSelect>
                    <span
                            style="
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 5px 0px 5px;
                        height: 1px;
                        width: 20px;

                        background-color: #999;
                    "
                    >
                </span>
                    <CSSelect class="yueF" i-width="40px" style="margin-left: 0">
                        <el-date-picker
                                v-model="dialogEndTimer"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择结束时间"
                                prefix-icon="el-icon-time"
                                :editable="false"
                        ></el-date-picker>
                    </CSSelect>


                </div>
            </CSDialog>

        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import ViewModal from "@/components/ViewModal.vue";
    import ViewImageModal from "@/components/ViewImageModal";
    import CSSelect from "@/components/common/CSSelect";
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";
    import {JOB_LEVEL, DEPARTMENT_TYPES} from "@/constant";
    import {STORAGE_KEY} from "@/constant";

    const DEFAULT_ROWS = 10;
    import {
        queryDepartmentStaffUrl,
        queryInspectTaskUrl,
        queryInspectItemLogUrl,
        queryInspectPointLog,
    } from "@/requestUrl";
    import dayjs from "dayjs";
    import CSTable from "@/components/common/CSTable";
    import CSDialog from "@/components/common/CSDialog";

    export default {
        props: {},
        components: {
            CSDialog,
            CSTable,
            Pagination,
            ViewModal,
            CSSelect,
            ViewImageModal,
            CSBreadcrumb,
        },
        created() {
            window.addEventListener("keydown", this.queryInspectTaskKeyDown);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.queryInspectTaskKeyDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.queryInspectTaskKeyDown);
        },
        data() {
            return {
                downState:false,
                dialogStartTimerMoun: dayjs(this.chooseMonth).startOf('month').format('YYYY-MM-DD'),
                dialogEndTimerMoun: dayjs(this.chooseMonth).startOf('month').format('YYYY-MM-DD'),
                tiemrOut: 2,
                filterHeight: 0,
                staffInfo: this.$vc.getCurrentStaffInfo(),
                queryParams: {
                    operatorId: "",
                    regionId: this.$vc.getCurrentRegion().communityId,
                    startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
                    endDate: dayjs().format('YYYY-MM-DD'),
                    performerId: "",
                    isTimeout: "",
                },
                dialogStartTimer: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
                dialogEndTimer: dayjs().format('YYYY-MM-DD'),
                staffList: [],
                inspectionTaskList: [],
                inspectPointLogShowStatus: {},
                inspectPlanType: {
                    [DEPARTMENT_TYPES.PROCTER_GAMBLE]: {
                        103: "清洁",
                        104: "绿化",
                        105: "其他",
                    },
                    [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: {
                        203: "巡查",
                        204: "其他",
                    },
                    [DEPARTMENT_TYPES.SECURITY]: {
                        304: "巡检",
                        305: "其他",
                    },
                    [DEPARTMENT_TYPES.ENGINEERING]: {
                        413: "巡检",
                        414: "维保",
                        415: "其他",
                    },
                },
                previousRoute: {},
                openDownLoadFlag: false
            };
        },

        mounted() {
            this.getStaffList();
            this.queryInspectTask();
            this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
                this.queryInspectTask(_currentPage);
            });
            this.previousRoute = JSON.parse(sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY))[0];
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            window.addEventListener('resize', () => {
                this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            })
        },
        methods: {
            changeTime() {
                // console.log('变化');
                // this.queryParams.startDate = null;
            },
            queryInspectTaskKeyDown(e) {
                if (e.keyCode == 13) {
                    this.queryInspectTask();
                }
            },
            getStaffList(query) {
                this.$fly
                    .post(queryDepartmentStaffUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        departmentCode: "",
                        jobLevel: "",
                        isOnTheJob: true,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.staffList = res.data;
                    });
            },
            queryInspectTask(page = 1, rows = DEFAULT_ROWS) {
                this.queryParams.pageNum = page;
                this.queryParams.pageSize = rows;
                this.$fly
                    .post(queryInspectTaskUrl, this.queryParams)
                    .then((res) => {
                        this.inspectionTaskList = res.data.datas;
                        if (page === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                currentPage: page,
                                total: res.data.total,
                                pageSize: rows,
                            });
                        }

                    });
            },
            lookInspectPointLog(taskId) {
                let currentPoint = {};
                if (this.inspectPointLogShowStatus[taskId] == null) {
                    currentPoint = {
                        status: true,
                        data: [],
                    };
                } else {
                    for (let key in this.inspectPointLogShowStatus[taskId]) {
                        if (
                            this.inspectPointLogShowStatus[taskId].hasOwnProperty(
                                key
                            )
                        ) {
                            currentPoint[key] = this.inspectPointLogShowStatus[
                                taskId
                                ][key];
                        }
                    }
                    currentPoint.status = !currentPoint.status;
                }
                this.$set(this.inspectPointLogShowStatus, taskId, currentPoint);
                if (currentPoint.status === false) {
                    return;
                }
                this.$fly
                    .post(queryInspectPointLog, {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        taskId: Number(taskId),
                        operatorId: this.$vc.getCurrentStaffInfo().id,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$set(
                            this.inspectPointLogShowStatus[taskId],
                            "data",
                            res.data
                        );
                    });
            },
            lookCheckInPhotos(photosStr) {
                this.$vc.emit(this.$route.path, "viewImageModal", "view", JSON.parse(photosStr));
            },
            // 判断权限
            judgmentAuthority: function () {
                let udutyType = Number(this.$vc.getCurrentStaffInfo().dutyType);
                if ([1001, 1002, 1012].includes(udutyType)) {
                    let types = {};
                    for (let firstKey in this.inspectPlanType) {
                        for (let secondKey in this.inspectPlanType[firstKey]) {
                            const secondKeyMapping = {
                                105:
                                    "保洁-" +
                                    this.inspectPlanType[firstKey][secondKey],
                                204:
                                    "客服-" +
                                    this.inspectPlanType[firstKey][secondKey],
                                305:
                                    "安保-" +
                                    this.inspectPlanType[firstKey][secondKey],
                                415:
                                    "工程-" +
                                    this.inspectPlanType[firstKey][secondKey],
                            };
                            types[secondKey] =
                                secondKeyMapping[secondKey] ||
                                this.inspectPlanType[firstKey][secondKey];
                        }
                    }
                    return types;
                }
                return this.inspectPlanType[udutyType] || '-';
            },
            checkPlanToStr(type) {
                let res = "-";
                if (type) {
                    switch (type) {
                        case 103:
                            res = "清洁";
                            break;
                        case 104:
                            res = "绿化";
                            break;
                        case 105:
                        case 204:
                        case 305:
                        case 415:
                            res = "其他";
                            break;
                        case 203:
                        case 304:
                        case 413:
                            res = "巡检";
                            break;
                        case 414:
                            res = "维保";
                            break;
                        default:
                            break;
                    }
                }
                return res;
            },
            lookInspectItemLog(pointLogId) {
                this.$router.push({
                    name: "inspectionCheckDetail",
                    params: {
                        pointLogId,
                    }
                })
            },
            openDownLoad() {
                this.openDownLoadFlag = true
            },
            downInspectTask() {
                // 、queryParams.startDate
                let param = {
                    regionCode: this.$vc.getCurrentRegion().code,
                    type: this.tiemrOut
                };
                if (this.tiemrOut == 2) {
                    this.dialogEndTimerMoun = dayjs(this.dialogStartTimerMoun).add(1, 'month').format('YYYY-MM-DD');
                    this.dialogStartTimerMoun = dayjs(this.dialogStartTimerMoun).startOf('month').format('YYYY-MM-DD');
                    param = {
                        ...param,
                        startTime: this.dialogStartTimerMoun,     //开始时间
                        endTime: this.dialogEndTimerMoun,       //结束时间
                        //类型 1按周 2按月
                    }
                } else {
                    param = {
                        ...param,
                        startTime: this.dialogStartTimer,     //开始时间
                        endTime: this.dialogEndTimer,       //结束时间
                        //类型 1按周 2按月
                    }
                }

                this.downState = true;
                let name = this.strToYearMethodDay(param.startTime, param.endTime, param.type);
                // alert(name)
                this.$fly.post("/inspection/inspection/downLoadInspectionTasks", param, {
                    responseType: 'arraybuffer',
                    headers: {
                        notParse: true,
                    }
                }).then((res) => {
                    const link = document.createElement('a')
                    let blob = new Blob([res], {type: 'application/vnd.ms-excel'});
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', name + '巡检任务记录.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    this.downState = false;
                })
                this.openDownLoadFlag = false;
            },
            strToYearMethodDay(str, str2, type) {
                let date1 = new Date(str);
                if (type == 1) {
                    let d1 = date1.getFullYear() + "年" + (date1.getMonth() + 1) + "月" + date1.getDate() + "日";
                    let date2 = new Date(str2);
                    let d2 = date2.getFullYear() + "年" + (date2.getMonth() + 1) + "月" + date2.getDate() + "日";
                    return d1 + "至" + d2;
                } else {
                    let d1 = date1.getFullYear() + "年" + (date1.getMonth() + 1) + "月";
                    return d1
                }
            }
        },
    };
</script>
<style >
    .inspectionTaskManage-container .el-icon-caret-bottom
    {
        padding-top: 3px;
    }
    .inspectionTaskManage-container .yueF {
        width: 220px;
        height: 40px;
        margin-left: 30px
    }

    .inspectionTaskManage-container .yueF .el-date-editor {
        height: 40px !important;
        font-size: 24px !important;
    }
    .grabbing {
        background-color: #D8FFF8;
        color: #00B694;
        width: 70px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        text-align: center;
        margin-right: 10px;
    }
</style>
